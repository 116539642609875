import Vue from 'vue'
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/k-gold-box.vue'),
    meta: {
      title: '消息推送'
    }
  },
  {
    path: '/k-gold',
    name: 'k-gold',
    component: () => import('../views/k-gold.vue'),
    meta: {
      title: 'k歌赢金币'
    }
  }
]
console.log(process.env.BASE_URL);
const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title;
  }
  // if(!localStorage.getItem('token') && to.path!='/login'){
  //   router.push('/login');
  //   return false;
  // }
  next();
})

export default router

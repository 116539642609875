import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Notify } from 'vant'
import { url } from '../js/config.js'

axios.defaults.withCredentials=true;
axios.defaults.timeout = 30*1000;
console.log(process.env.NODE_ENV,'------')
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '/apis' : url;
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
/*全局拦截axios*/
axios.interceptors.request.use(
  config => {
    return config
  },
  err => {
    return Promise.reject(err)
  }
)
// 设置axios的返回拦截（还可以设置request拦截）
axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if(error.message.includes('timeout')){   // 判断请求异常信息中是否含有超时timeout字符串
      console.log("错误回调", error); 
      Notify({ type: 'warning', message: "网速过慢，响应超时!"});
      return Promise.reject(error);          // reject这个错误信息
    }
    return Promise.reject(error);
  }
);


Vue.use(VueAxios, axios)